// Libraries
import React, { useState, useMemo, useEffect, useContext } from 'react';
import { StyleSheet, Dimensions, View, ImageBackground, TouchableWithoutFeedback, Text, ScrollView, Image} from 'react-native';
import { FontAwesome5, AntDesign, Entypo} from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Styles
import FncColors from '../styles/colors';
let Colors = [];
import { CreateResponsiveStyle, DEVICE_SIZES, minSize, maxSize } from 'rn-responsive-styles';

// Functions
import { decideCSS, decideEnable, decideShow, verwerkData, getKeyByValue, isNumeric, stripFirstColon, customConsoleLog, hoverOutsideCard, sendGa } from '../functions/common';

// Context
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_PARAM, RESET_PARAM } from '../context/actions/commonActions';

// Components
import { P, H1, H2, H3, H4 } from '../components/text';
import { Button, ButtonVerfijn } from './button';
import IconSetter from './iconSetter';

// Variables


export default function StartContainer(props) {
  Colors = FncColors();
  //Context
  const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
  const commonContext = useContext(GlobalContext).commonState;
  const debug = commonContext.debug;
  let param = commonContext.param;
  const param_cache = commonContext.param_cache;

  //Props
  const mobile = props.mobile || false;
  const tablet = props.tablet;
  const navigation = props.navigation;
  const module = props.module;
  const { styles } = useStyles();

  useEffect(() => {
    sendGa(module.mod_naam, 'start');
  },[]);

  const start_module = () => {
    const param = { goto: "first", moduleNaam: module.mod_naam, chain: [] };
    commonDispatch( { type: SET_PARAM, value: param } );
    navigation.push( "Main", {module: module.mod_naam });
  }

  const home = () => {
    props.navigation.push('Main');
  }

  const goBack = () => {
    const param = { goto: param_cache.chain[param_cache.chain.length -1].vervolgstapId, moduleNaam: module.mod_naam, chain: param_cache.chain };
    commonDispatch( { type: SET_PARAM, value: param } );
    navigation.push( "Main", {module: module.mod_naam });
  }

  return (
    <View style={styles('container')}>
      <View style={styles('splitScreen')}>
        <View style={styles('imageView')}>
        <Image aria-hidden={true}  style={[styles('image')]} source={global.settings.useLocalImage ?  mobile ? require('../assets/images/'+ global.settings.image_folder +'/background_mobiel.jpeg') :  require('../assets/images/'+ global.settings.image_folder +'/background.jpeg') : { uri:  mobile ? module.mod_achtergrond_portrait : module.mod_achtergrond_landscape}}/>
        </View>
        <View style={[styles('col')]}>
          <H1 
            realHeader={true}
            level="1"
            accessible={true}
            color={Colors[2]}
            alignSelf='left'
          >
            {global.settings.moduleTitle ? global.settings.text_to_upper_case == 0 ? global.settings.moduleTitle : global.settings.moduleTitle.toUpperCase() : global.settings.text_to_upper_case == 0 ? module.mod_titel : module.mod_titel.toUpperCase()}
          </H1>
          <View accessible={true} style={styles('htmlTekstPlacer')}>
            <div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{
              __html:
                module.mod_inleiding
            }} />
          </View>
          <View style={ mobile ? {position: 'fixed', bottom: 0, zIndex: 999999999, backgroundColor: 'white', borderTopWidth: 1, borderTopColor:'#DEDEDE', justifyContent: 'center', width: '100%', right: 0, flexDirection: 'row'} : { position: 'relative', flexDirection: 'row'}}>
                <Button 
                  role="link"
                  arrowIcon={true} 
                  arrowColor={Colors[10]}
                  color={Colors[7]} 
                  square={global.settings.border_radius === 0 ? true : false}
                  alignSelf={'flex-start'} 
                  fontSize={17}
                  fontFamily={'Semibold'} 
                  textColor={Colors[16]}
                  height={46} 
                  iconOrientation='right' 
                  caption={global.settings.text_to_upper_case == 0 ? 'Start' : 'START'} 
                  onPress={() => start_module()} 
                />
          </View>
        </View>
      </View>
    </View>
  );

}



// Style
const styler = StyleSheet.create({
  fullScreen: {
    flexDirection: 'row',
    width: '100%',
  },

});

const useStyles = CreateResponsiveStyle(
  {},
  {
    // Will apply the size 30 font to large and extra large devices
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      titel: {
        fontFamily: 'Regular',
        fontWeight: '700',
        fontSize: 26,
        marginLeft: 10,
      },
      htmlTekstPlacer: {
        marginVertical: 10,
        marginBottom: 60
      },
      imageView: {
        width: '100%'
      },
      card: {
        width: '100%'
      },
      container: {
        flex: 1,
        paddingHorizontal: 30,
        backgroundColor: 'white',
      },
      col: {
        width: '100%',
      },
      splitScreen: {
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      },
      image: {
        height: 210,
        marginBottom: 10,
        alignSelf: 'center',
        width: Dimensions.get('window').width,
        resizeMode: "contain",
      },
    },
    [minSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      image: {
        height: 450,
        width: '100%',
        maxWidth: 550,
        resizeMode: "contain",
        alignSelf: 'flex-start',
        left: -25,
        marginBottom: 20
      },
      htmlTekstPlacer: {
        marginVertical: 10,
        marginBottom: 25,
        left: 25,
        maxWidth: '90%'
      },
      imageView: {
        alignSelf: 'center',
        width: '100%',
      },
      titel: {
        fontFamily: 'Regular',
        fontWeight: '700',
        fontSize: 28,
        marginLeft: 25,
      },
      col: {
        width: '100%',
        justifyContent: 'center',
      },
      splitScreen: {
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        flex: 1,
      },
      container: {
        flex: 1,
        padding: 40,
        backgroundColor: 'white',
      },
    },
    [minSize(DEVICE_SIZES.LARGE_DEVICE)] : {
      col: {
        width: '50%',
        margin: 10,
      },
      imageView: {
        width: '50%',
        margin: 10,
        justifyContent: 'center'
      },
      htmlTekstPlacer: {
        marginVertical: 20,
        marginBottom: 20,
        left: 10
      },
      titel: {
        fontFamily: 'Semibold',
        fontWeight: '700',
        fontSize: 28,
        marginLeft: 9,
      },
      card: {
        width: '49.5%'
      },
      splitScreen: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        flex: 1,
      },
      image: {
        height: 500,
        width: '100%',
        maxWidth: 550,
        resizeMode: "contain",
        alignSelf: 'flex-end',
        justifyContent: "flex-end",
        bottom: 20
      },
    },
    [minSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)] : {
      col: {
        width: '50%',
        margin: 0,
      },
      image: {
        height: 500,
        width: '100%',
        maxWidth: 550,
        resizeMode: "contain",
        alignSelf: 'flex-end',
        justifyContent: "center",
        bottom: 20
      },
      card: {
        width: '33%'
      },
    }
  }
)